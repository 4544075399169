<template>
  <layout-single>
    <template #content>
      <v-container>
        <v-row>
          <v-col align="center">
            <h1>{{ $t('Choose event') }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>

        <v-row v-if="search !== ''">
          <v-col align="left">
            <strong>Visar {{ filteredEvents.length }} provtillfällen av {{ allEvents.length }}</strong>
          </v-col>
        </v-row>

        <v-row v-if="filteredEvents && filteredEvents.length">
          <v-list class="transparent" style="width: 100%">
            <v-list-item
              v-for="event in filteredEvents"
              :key="event.id"
              @click="redirect(`/event/${event.id}`)"
            >
              <v-list-item-content align="left">
                <v-list-item-title>{{ event.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ classList(event) }}
                </v-list-item-subtitle>

                <v-list-item-subtitle v-if="event.description">
                  {{ event.description }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn elevation="0" icon>
                  <v-icon color="grey lighten-1">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-row>

        <v-row v-else-if="search === ''">
          <v-col align="center">
            Kunde inte hitta några provtillfällen
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout-single>
</template>

<script>
import { createLogger, mapState } from 'vuex'

import LayoutSingle from '@/components/layout-single'

export default {
  name: 'EventList',

  components: {
    LayoutSingle
  },

  data: () => ({
    search: '',
  }),

  computed: {
    ...mapState({
      events: state => state.rx.events.sort((a, b) => {
        return a.date.from - b.date.from;
      })
    }),
    allEvents() {
        return this.events.filter((e) =>
          this.$store.state.rx.registrations.filter(
            (r) => r.eventId === e.id
          ).length > 0
        );
    },
    filteredEvents() {
      const events = this.allEvents;

      if (this.search === '') {
        return events;
      }

      const search = this.search.toLowerCase();

      return events.filter((e) => (
        [
          e.title.toLowerCase().includes(search),
          e.categories.map((c) => c.value.toLowerCase()).some((s) => s.includes(search)),
        ].some((s) => s === true)
      ))
    }
  },

  mounted () {
    this.$store.commit('setNavigation', {
      show: true,
      to: null,
      title: 'Events'
    })
  },

  methods: {
    classList(event) {
      return event.categories.map((c) => c.value).join(' / ')
    }
  }
}
</script>
